.-ilMeG_main {
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding: 80px 0;
  display: flex;
}

.KwIz1G_container {
  flex-direction: column;
  display: flex;
}

._1nQ64q_header {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  display: flex;
}

._1nQ64q_title {
  text-align: center;
  color: var(--color-default);
  margin: 0;
  font-size: 1.5rem;
  font-weight: 900;
  line-height: 1.33;
}

.keNwNa_block {
  background-color: var(--color-white);
  border-radius: 20px;
  padding: 32px 12px;
}

.FdN44q_button {
  text-align: center;
  background-color: var(--color-white);
  box-shadow: none;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  -webkit-user-select: none;
  user-select: none;
  border: none;
  border-radius: 24px;
  outline: none;
  min-height: 44px;
  padding: 12px 28px;
  font-size: 16px;
  font-weight: 700;
  transition: background-color .2s, box-shadow .2s;
}

.FdN44q_button::-moz-focus-inner {
  border: 0;
}

.FdN44q_button:hover, .FdN44q_button:focus, .FdN44q_button:active {
  outline: none;
  text-decoration: none;
}

.FdN44q_button:not(.FdN44q_loading).FdN44q_disabled {
  cursor: not-allowed;
  opacity: .5;
}

a.FdN44q_button {
  text-decoration: none;
  display: inline-flex;
}

.FdN44q_small {
  min-height: 36px;
  padding: 10px 24px;
  font-size: 14px;
  line-height: 16px;
}

.FdN44q_primary {
  color: var(--color-white);
  background-color: var(--color-primary);
  box-shadow: 0 5px 12px #30649e33;
}

.FdN44q_primary:hover:not(.FdN44q_disabled) {
  background-color: #3772b4;
}

.FdN44q_primary:active:not(.FdN44q_disabled) {
  box-shadow: none;
  background-color: #30649e;
}

.FdN44q_success {
  color: var(--color-white);
  background-color: #a4e650;
  box-shadow: 0 5px 10px #a4e7504d;
}

.FdN44q_success:hover:not(.FdN44q_disabled) {
  background-color: #abed57;
}

.FdN44q_success:active:not(.FdN44q_disabled) {
  box-shadow: none;
  background-color: #9ad949;
}

.FdN44q_secondary {
  color: #1b1d1e;
  background-color: var(--color-white);
  box-shadow: 0 5px 12px #989fa34d;
}

.FdN44q_secondary:hover:not(.FdN44q_disabled) {
  color: #484a4b;
}

.FdN44q_secondary:active:not(.FdN44q_disabled) {
  color: var(--color-default);
  box-shadow: none;
  background-color: #f8f9fa;
}

.FdN44q_brand {
  color: var(--color-contrast);
  background-color: --color-brand;
  box-shadow: 0 5px 12px #f4690333;
}

.FdN44q_brand:hover:not(.FdN44q_disabled) {
  background-color: #f46903;
}

.FdN44q_brand:active:not(.FdN44q_disabled) {
  box-shadow: none;
  background-color: #eb6300;
}

.FdN44q_info {
  color: var(--color-white);
  background-color: #989fa3;
  box-shadow: 0 5px 12px #989fa34d;
}

.FdN44q_info:hover:not(.FdN44q_disabled) {
  background-color: #a3acb1;
}

.FdN44q_info:active:not(.FdN44q_disabled) {
  box-shadow: none;
  background-color: #969ea3;
}

.FdN44q_shell, .FdN44q_link {
  color: var(--color-default);
  text-align: left;
  background: none;
  border-radius: 0;
  min-height: auto;
  padding: 0;
  font-weight: normal;
  line-height: 0;
  display: inline-block;
}

.FdN44q_shell:hover:not(.FdN44q_disabled), .FdN44q_link:hover:not(.FdN44q_disabled) {
  opacity: .8;
}

.FdN44q_shell:active:not(.FdN44q_disabled), .FdN44q_link:active:not(.FdN44q_disabled) {
  opacity: 1;
}

.FdN44q_shell.FdN44q_loading, .FdN44q_loading.FdN44q_link {
  background-color: inherit;
  box-shadow: inherit;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
}

.FdN44q_link {
  color: var(--color-primary);
  word-break: break-word;
  line-height: normal;
}

.FdN44q_loading {
  color: #0000;
  background-color: var(--color-white);
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  background-image: url("spinner.5c72a821.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 30px;
  box-shadow: 0 5px 12px #989fa34d;
}

.FdN44q_loading:hover, .FdN44q_loading:active {
  background-color: get-color("background", "primary");
}

.FdN44q_loading > * {
  visibility: hidden;
}

.MxypyG_formError {
  color: var(--color-error);
  margin: -2px;
  font-size: .75rem;
  line-height: 1.33;
}

._6eBlhW_formGroup {
  width: 100%;
  margin-bottom: 24px;
}

._6eBlhW_formGroup:last-child {
  margin-bottom: 0;
}

.EJIm4W_container {
  position: relative;
}

.EJIm4W_prefix {
  color: #bbb;
  font-family: var(--font-default);
  font-size: 1.05rem;
  position: absolute;
  top: 4px;
  left: -1px;
}

.EJIm4W_disabled .EJIm4W_prefix {
  opacity: .7;
}

.EJIm4W_input {
  border: none;
  border-bottom: 1px solid var(--color-border);
  font-size: 1.25rem;
  line-height: 1.4;
  font-family: var(--font-default);
  outline: none;
  width: 100%;
  transition: border-bottom-color .2s ease-in-out;
}

.EJIm4W_input::placeholder {
  color: var(--color-text-gray);
}

.EJIm4W_input:focus {
  border-bottom: 1px solid var(--color-brand);
}

.EJIm4W_input[type="number"] {
  -moz-appearance: textfield;
}

.EJIm4W_input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.EJIm4W_input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.EJIm4W_input::-ms-clear {
  display: none;
}

.EJIm4W_input::-ms-reveal {
  display: none;
}

.EJIm4W_input:disabled {
  cursor: not-allowed;
  opacity: .7;
}

.EJIm4W_input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMSIgdmlld0JveD0iMCAwIDExIDExIj4KICA8ZGVmcy8+CiAgPHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMWExNzE3IiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik04Ljc1IDEuOTdMMS42NCA5bTcuMDcuMDRMMS42OCAxLjkzIi8+Cjwvc3ZnPgo=") 0 0 / contain no-repeat;
  width: 11px;
  height: 11px;
}

.EJIm4W_input[type="search"]::-ms-clear {
  -webkit-appearance: none;
  appearance: none;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMSIgdmlld0JveD0iMCAwIDExIDExIj4KICA8ZGVmcy8+CiAgPHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMWExNzE3IiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik04Ljc1IDEuOTdMMS42NCA5bTcuMDcuMDRMMS42OCAxLjkzIi8+Cjwvc3ZnPgo=") 0 0 / contain no-repeat;
  width: 11px;
  height: 11px;
}

.eopgGW_label {
  color: #989fa3;
  align-items: center;
  font-size: .75rem;
  line-height: 1rem;
  display: flex;
}

.eopgGW_icon {
  margin-left: 8px;
}

._5dGF2q_field {
  width: 100%;
}

._5dGF2q_hidden {
  display: none;
}

._5dGF2q_showError {
  margin-bottom: -20px;
}

._5dGF2q_withError {
  border-bottom: 1px solid var(--color-error) !important;
}

.ij2CVG_container {
  gap: 5px;
  width: 100%;
  max-width: 576px;
  height: 36px;
  display: flex;
  position: relative;
}

.ij2CVG_input {
  border: none;
  border-bottom: 1px solid var(--color-border);
  font-size: 1.25rem;
  line-height: 1.4;
  font-family: var(--font-default);
  background-color: var(--color-white);
  color: var(--color-default);
  outline: none;
  transition: border-bottom-color .2s ease-in-out;
}

.ij2CVG_inactive {
  color: var(--color-text-gray);
  background-color: #0000;
}

.ij2CVG_focus {
  color: var(--color-default);
  border-bottom: 1px solid var(--color-brand);
}

.ij2CVG_error {
  border-bottom: 1px solid var(--color-error);
}

.w8rdMG_alignContentCenter {
  align-content: center;
}

.w8rdMG_alignContentFlexEnd {
  align-content: flex-end;
}

.w8rdMG_alignContentFlexStart {
  align-content: flex-start;
}

.w8rdMG_alignContentSpaceAround {
  align-content: space-around;
}

.w8rdMG_alignContentSpaceBetween {
  align-content: space-between;
}

.w8rdMG_alignContentStretch {
  align-content: stretch;
}

.w8rdMG_alignItemsCenter {
  align-items: center;
}

.w8rdMG_alignItemsBaseline {
  align-items: baseline;
}

.w8rdMG_alignItemsFlexEnd {
  align-items: flex-end;
}

.w8rdMG_alignItemsFlexStart {
  align-items: flex-start;
}

.w8rdMG_alignItemsStretch {
  align-items: stretch;
}

.w8rdMG_displayFlex {
  display: flex;
}

.w8rdMG_displayInlineFlex {
  display: inline-flex;
}

.w8rdMG_flexDirectionRow {
  flex-direction: row;
}

.w8rdMG_flexDirectionRowReverse {
  flex-direction: row-reverse;
}

.w8rdMG_flexDirectionColumn {
  flex-direction: column;
}

.w8rdMG_flexDirectionColumnReverse {
  flex-direction: column-reverse;
}

.w8rdMG_flexWrapWrap {
  flex-wrap: wrap;
}

.w8rdMG_flexWrapNowrap {
  flex-wrap: nowrap;
}

.w8rdMG_flexWrapWrapReverse {
  flex-wrap: wrap-reverse;
}

.w8rdMG_justifyContentCenter {
  justify-content: center;
}

.w8rdMG_justifyContentFlexEnd {
  justify-content: flex-end;
}

.w8rdMG_justifyContentFlexStart {
  justify-content: flex-start;
}

.w8rdMG_justifyContentSpaceAround {
  justify-content: space-around;
}

.w8rdMG_justifyContentSpaceBetween {
  justify-content: space-between;
}

.w8rdMG_justifyContentSpaceEvenly {
  justify-content: space-evenly;
}

.w8rdMG_justifyContentStretch {
  justify-content: stretch;
}

.w8rdMG_fluid {
  width: 100%;
  height: 100%;
}

.i4eskG_item {
  width: 100%;
}

.i4eskG_alignSelfBaseline {
  align-self: baseline;
}

.i4eskG_alignSelfAuto {
  align-self: auto;
}

.i4eskG_alignSelfCenter {
  align-self: center;
}

.i4eskG_alignSelfFlexEnd {
  align-self: flex-end;
}

.i4eskG_alignSelfFlexStart {
  align-self: flex-start;
}

.i4eskG_alignSelfStretch {
  align-self: stretch;
}

.CUrLHG_checkbox {
  display: none;
}

.CUrLHG_label {
  cursor: pointer;
  display: inline-flex;
  position: relative;
}

.CUrLHG_label:empty {
  line-height: 0;
}

.CUrLHG_label:before, .CUrLHG_label:after {
  content: "";
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  display: block;
}

.CUrLHG_label:before {
  border: 1px solid #e2e7e7;
  border-radius: 6px;
}

.CUrLHG_label:after {
  position: absolute;
  left: 0;
}

.CUrLHG_hasLabel.CUrLHG_label:before {
  margin-right: 15px;
}

.CUrLHG_checkbox:checked ~ .CUrLHG_label:before {
  background: #a4e750;
  border-color: #a4e750;
}

.CUrLHG_checkbox:checked ~ .CUrLHG_label:after {
  filter: invert() brightness(150%) contrast();
  background: url("tick.a0be4199.svg") center / 16px 16px no-repeat;
}

.CUrLHG_light .CUrLHG_label {
  color: #868b8e;
  letter-spacing: .3px;
  font-size: 13px;
  line-height: 18px;
}

.CUrLHG_light .CUrLHG_label a {
  color: #868b8e;
}

.CUrLHG_readOnly {
  cursor: unset;
  pointer-events: none;
}

.CUrLHG_disabled {
  opacity: .4;
}

.V8lgQW_tooltipTarget {
  display: contents;
}

.V8lgQW_tooltip {
  z-index: 1;
  word-break: break-word;
  background-color: var(--color-white);
  filter: drop-shadow(0 5px 12px #0000000d);
  border-radius: 10px;
  max-width: 50%;
  padding: 8px 12px;
}

.V8lgQW_tooltip .V8lgQW_arrow {
  margin: 0 auto;
  line-height: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.V8lgQW_tooltip[data-placement="top"] .V8lgQW_arrow {
  transform: rotate3d(1, 0, 0, 180deg)translate3d(0, -100%, 0);
}

.V8lgQW_tooltip[data-placement="top-start"] .V8lgQW_arrow {
  left: 12px;
  right: auto;
  transform: rotate3d(1, 0, 0, 180deg)translate3d(0, -100%, 0);
}

.V8lgQW_tooltip[data-placement="top-end"] .V8lgQW_arrow {
  left: auto;
  right: 12px;
  transform: rotate3d(1, 0, 0, 180deg)translate3d(0, -100%, 0);
}

.V8lgQW_tooltip[data-placement*="right"] .V8lgQW_arrow {
  margin: auto 0;
  top: 0;
  left: -6px;
  right: auto;
  transform: rotate3d(0, 0, 1, -90deg)translate3d(0, -100%, 0);
}

.V8lgQW_tooltip[data-placement*="left"] .V8lgQW_arrow {
  margin: auto 0;
  top: 0;
  left: auto;
  right: -6px;
  transform: rotate3d(0, 0, 1, 90deg)translate3d(0, -100%, 0);
}

.V8lgQW_tooltip[data-placement="bottom"] .V8lgQW_arrow {
  top: 0;
  bottom: auto;
  transform: translate3d(0, -100%, 0);
}

.V8lgQW_tooltip[data-placement="bottom-start"] .V8lgQW_arrow {
  inset: 0 auto auto 12px;
  transform: translate3d(0, -100%, 0);
}

.V8lgQW_tooltip[data-placement="bottom-end"] .V8lgQW_arrow {
  inset: 0 12px auto auto;
  transform: translate3d(0, -100%, 0);
}

.rJjzcG_hint {
  padding: 25px 30px;
  font-size: 15px;
}

.rJjzcG_icon {
  cursor: pointer;
}

.dWRPPW_form {
  width: 100%;
  min-width: 536px;
  max-width: 536px;
}

.dWRPPW_form .dWRPPW_block {
  padding: 32px;
}

.dWRPPW_description {
  text-align: center;
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
}

.dWRPPW_description > a {
  color: var(--color-brand);
  text-decoration: none;
}

.dWRPPW_description > a:hover {
  text-decoration: underline;
}

.dWRPPW_input {
  margin-top: 24px;
}

.dWRPPW_error {
  text-align: center;
}

.dWRPPW_footer {
  justify-content: center;
  margin-top: 24px;
  display: flex;
}

@media only screen and (width <= 600px) {
  .dWRPPW_form {
    width: 300px;
    min-width: auto;
  }
}

.RjDckG_description {
  text-align: center;
  color: var(--color-text-secondary);
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
}

.sikyBq_container {
  padding-top: 120px;
}

.U2mm_G_form {
  width: 100%;
  min-width: 536px;
}

.U2mm_G_blockContainer {
  padding: 32px 12px;
}

.U2mm_G_formContainer {
  padding: 0 20px;
}

.U2mm_G_phoneContainer {
  display: flex;
}

.U2mm_G_phoneInput {
  flex-grow: 1;
  align-items: flex-end;
  margin-left: 24px;
  display: flex;
}

.U2mm_G_phoneInput div {
  flex-grow: 1;
}

.U2mm_G_footer {
  flex-direction: column;
  display: flex;
}

.U2mm_G_buttons {
  justify-content: center;
  gap: 12px;
  margin-top: 12px;
  display: flex;
}

@media only screen and (width <= 600px) {
  .U2mm_G_form {
    width: 300px;
    min-width: auto;
  }
}

.XKLWca_fieldset {
  border: none;
  min-width: 0;
  margin: 0 0 12px;
  padding: 0;
}

.XKLWca_legend {
  background-color: #f8f9fa;
  border-radius: 8px;
  width: 100%;
  padding: 8px 16px;
}

.XKLWca_legendContent {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.XKLWca_title {
  text-transform: uppercase;
  letter-spacing: 1.7px;
  align-items: center;
  margin: 0;
  font-size: .75rem;
  font-weight: 900;
  display: flex;
}

.XKLWca_content {
  padding: 24px 12px 0;
}

.ItJ91q_toggle {
  display: inline-flex;
}

.ItJ91q_content {
  z-index: 1;
}

.rIuonq_default {
  letter-spacing: .3px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px 12px;
  font-size: 13px;
  display: flex;
  position: relative;
}

.rIuonq_title {
  white-space: nowrap;
  margin: 0;
}

.rIuonq_actionsContainer {
  cursor: pointer;
  justify-content: flex-end;
  margin-left: 10px;
  display: flex;
}

.rIuonq_actionOk:hover {
  opacity: .8;
}

.rIuonq_actionClear {
  padding-right: 8px !important;
}

.rIuonq_actionClear:hover {
  color: #333;
}

.mH5IGa_default {
  letter-spacing: .3px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px 12px;
  font-size: 13px;
  display: flex;
}

.mH5IGa_title {
  letter-spacing: .3px;
  color: #989fa3;
  font-size: 12px;
  line-height: 16px;
}

.mH5IGa_actionsContainer {
  cursor: pointer;
  justify-content: flex-end;
  margin-left: 10px;
  display: flex;
}

.mH5IGa_actionOk:hover {
  opacity: .8;
}

.mH5IGa_actionClear {
  padding-right: 8px;
}

.mH5IGa_actionClear:hover {
  color: #333;
}

.pmhyQq_option {
  cursor: pointer;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  display: flex;
  position: relative;
}

.pmhyQq_highlighted {
  background-color: var(--color-background);
}

.pmhyQq_label {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 100%;
  margin: 0;
  padding: 8px 0;
  overflow: hidden;
}

.pmhyQq_icon {
  fill: var(--color-success);
  flex-shrink: 0;
  margin-left: 10px;
  padding: 0;
}

.pmhyQq_option:not(.pmhyQq_selected) .pmhyQq_icon {
  visibility: hidden;
}

._5d-v2q_ellipsis {
  text-overflow: ellipsis;
  word-break: break-word;
  -webkit-box-orient: vertical;
  max-width: 100%;
  margin: 0;
  display: -webkit-box;
  overflow: hidden;
}

._5d-v2q_noBox {
  white-space: nowrap;
  display: block;
}

.mOHYuq_loader {
  background: transparent var(--loader-src) no-repeat center;
  background-size: var(--loader-width) var(--loader-height);
  flex: 100%;
  width: 100%;
  min-height: 100%;
}

.mOHYuq_loader:after {
  width: var(--loader-width);
  height: var(--loader-height);
  content: "";
  display: block;
}

.gXwW4G_optionLoader {
  align-items: center;
  padding: 0 20px;
  display: flex;
}

.gXwW4G_loader {
  flex: none;
  width: auto;
  min-width: auto;
}

.gXwW4G_loadingLabel {
  margin-left: 10px;
  font-size: 15px;
  font-weight: 500;
  line-height: 40px;
}

.W9eVXG_select {
  width: 100%;
  display: block;
  position: relative;
}

.W9eVXG_valueContainer {
  padding-right: 16px;
}

.W9eVXG_hide {
  z-index: -1;
  opacity: 0;
  position: absolute;
}

.W9eVXG_selectValue {
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  background-color: #0000;
  background-image: none;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 0 100%, 100% 100%;
  background-attachment: scroll;
  background-origin: padding-box;
  background-clip: border-box;
  border: none;
  border-radius: 0;
  outline: none;
  font-size: 1.25rem;
  transition: background ease-out;
  overflow: hidden;
  background-image: linear-gradient(0deg, #a4e750 1px, #00968800 0), linear-gradient(0deg, #e8ecec 1px, #0000 0) !important;
}

.W9eVXG_selectValue:focus {
  background-size: 100% 100%, 100% 100%;
  transition-duration: .3s;
}

.W9eVXG_selectValue:empty:before {
  color: var(--color-text-gray);
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  content: attr(data-placeholder);
  overflow: hidden;
}

.W9eVXG_arrowIcon {
  pointer-events: none;
  width: 16px;
  height: 16px;
  transition: transform .5s;
  display: block;
  position: absolute;
  top: calc(50% - 8px);
  right: 0;
}

.W9eVXG_isOpen {
  transform: scaleY(-1);
}

.W9eVXG_disabled {
  cursor: not-allowed;
  opacity: .3;
}

.W9eVXG_disabled .W9eVXG_selectValue, .W9eVXG_disabled .W9eVXG_icon {
  pointer-events: none;
}

.W9eVXG_menu {
  z-index: 1;
  background-color: var(--color-white);
  -webkit-user-select: none;
  user-select: none;
  border-radius: 12px;
  padding: 24px 0;
  overflow: hidden;
  box-shadow: 0 7px 15px #00000026;
}

.W9eVXG_options {
  max-height: 280px;
  padding: 0 12px;
  overflow-y: auto;
}

.W9eVXG_addNew {
  width: calc(100% + 24px);
  margin: 0 -12px;
  padding: 15px 32px;
}

.W9eVXG_hasAddNew.W9eVXG_menu {
  padding-bottom: 0;
}

.W9eVXG_noOptionsTitle {
  margin: 0;
  padding: 0 10px;
  font-size: 15px;
  font-weight: 500;
  line-height: 40px;
}

.wBsxDG_addNew {
  border-top: 1px solid var(--color-border);
  align-items: center;
  display: flex;
}

.wBsxDG_addNew:hover {
  background-color: var(--color-utility);
}

.wBsxDG_button {
  background-color: var(--color-success);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  line-height: 1;
  display: flex;
  box-shadow: 0 5px 10px #00c6814d;
}

.wBsxDG_label {
  letter-spacing: .5px;
  margin: 0 0 0 10px;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
}

.N3iWtq_stateLabel {
  opacity: .5;
  margin-left: 10px;
}

.YRDlzG_container {
  align-items: end !important;
}

.YRDlzG_auto {
  width: 135px !important;
}

.q1_Vqq_relative {
  position: relative;
}

.q1_Vqq_grid {
  display: grid;
}

.q1_Vqq_inlineGrid {
  display: inline-grid;
}

.nqEAca_formArrayField {
  margin-bottom: 0;
}

.zCe_SG_deleteButton {
  margin: 5px 0;
  display: none;
}

.zCe_SG_deletableGroup:hover .zCe_SG_deleteButton {
  display: block;
}

.zCe_SG_deleteButton svg {
  fill: var(--color-info);
}

.zCe_SG_deleteButton:hover svg:hover {
  fill: var(--color-default);
}

.kv45zW_formArrayField:not(:last-child) {
  margin-bottom: 40px;
}

.kv45zW_addButton {
  margin-top: 10px;
}

.kv45zW_error {
  text-align: unset;
  margin: 15px 0 0;
}

.oOwHRq_control {
  border-radius: 15px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 30px;
  height: 40px;
  padding: 8px 0;
  display: flex;
  box-shadow: 0 5px 12px #0000000d;
}

.oOwHRq_button {
  opacity: .5;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 15px;
  transition: opacity .5s;
  display: flex;
  position: relative;
}

.oOwHRq_button:hover {
  opacity: 1;
}

.oOwHRq_button:disabled {
  opacity: .1 !important;
}

.oOwHRq_button:first-child {
  transform: rotate(-180deg);
}

.oOwHRq_icon {
  pointer-events: none;
  position: absolute;
  top: -5px;
}

.ML33Ya_button {
  min-height: initial;
  padding: initial;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  display: inline-flex;
}

.ML33Ya_button .ML33Ya_label {
  padding: 0 0 0 15px;
}

.ML33Ya_button:hover {
  opacity: 1;
}

.ML33Ya_button:hover .ML33Ya_label {
  color: #484a4b;
}

.ML33Ya_button:active .ML33Ya_label {
  color: var(--color-default);
  box-shadow: none;
  background-color: #f8f9fa;
}

.ML33Ya_icon {
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.ML33Ya_plus {
  max-width: 100%;
  height: auto;
}

.ML33Ya_primary .ML33Ya_icon {
  color: var(--color-white);
  background-color: var(--color-primary);
  box-shadow: 0 5px 12px #30649e33;
}

.ML33Ya_primary .ML33Ya_plus {
  fill: var(--color-white);
}

.ML33Ya_primary:hover:not(.ML33Ya_disabled) .ML33Ya_icon {
  background-color: #3772b4;
}

.ML33Ya_primary:active:not(.ML33Ya_disabled) .ML33Ya_icon {
  box-shadow: none;
  background-color: #30649e;
}

.ML33Ya_success .ML33Ya_icon {
  color: var(--color-white);
  background-color: #a4e650;
  box-shadow: 0 5px 10px #a4e7504d;
}

.ML33Ya_success .ML33Ya_plus {
  fill: var(--color-white);
}

.ML33Ya_success:hover:not(.ML33Ya_disabled) .ML33Ya_icon {
  background-color: #abed57;
}

.ML33Ya_success:active:not(.ML33Ya_disabled) .ML33Ya_icon {
  box-shadow: none;
  background-color: #9ad949;
}

.ML33Ya_secondary .ML33Ya_icon {
  color: #1b1d1e;
  background-color: var(--color-white);
  box-shadow: 0 5px 12px #989fa34d;
}

.ML33Ya_secondary .ML33Ya_plus {
  fill: #1b1d1e;
}

.ML33Ya_secondary:hover:not(.ML33Ya_disabled) .ML33Ya_icon {
  color: #484a4b;
}

.ML33Ya_secondary:active:not(.ML33Ya_disabled) .ML33Ya_icon {
  color: var(--color-default);
  box-shadow: none;
  background-color: #f8f9fa;
}

.ML33Ya_brand .ML33Ya_icon {
  color: var(--color-white);
  background-color: --color-brand;
  box-shadow: 0 5px 12px #f4690333;
}

.ML33Ya_brand .ML33Ya_plus {
  fill: var(--color-white);
}

.ML33Ya_brand:hover:not(.ML33Ya_disabled) .ML33Ya_icon {
  background-color: #f46903;
}

.ML33Ya_brand:active:not(.ML33Ya_disabled) .ML33Ya_icon {
  box-shadow: none;
  background-color: #eb6300;
}

.ML33Ya_info .ML33Ya_icon {
  color: var(--color-white);
  background-color: #989fa3;
  box-shadow: 0 5px 12px #989fa34d;
}

.ML33Ya_info .ML33Ya_plus {
  fill: var(--color-white);
}

.ML33Ya_info:hover:not(.ML33Ya_disabled) .ML33Ya_icon {
  background-color: #a3acb1;
}

.ML33Ya_info:active:not(.ML33Ya_disabled) .ML33Ya_icon {
  box-shadow: none;
  background-color: #969ea3;
}

.ML33Ya_loading {
  pointer-events: none;
}

.ML33Ya_loading .ML33Ya_icon {
  color: #0000;
  background-color: var(--color-white);
  background-image: url("spinner.5c72a821.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 30px;
  box-shadow: 0 5px 12px #989fa34d;
}

.ML33Ya_loading .ML33Ya_icon > * {
  visibility: hidden;
}

.ML33Ya_loading .ML33Ya_label {
  color: #484a4b;
}

.ML33Ya_loading:hover .ML33Ya_icon, .ML33Ya_loading:active .ML33Ya_icon {
  background-color: get-color("background", "primary");
}

.ML33Ya_loading:hover .ML33Ya_label, .ML33Ya_loading:active .ML33Ya_label {
  color: #484a4b;
}

.Lkja6W_textArea {
  resize: none;
  border: none;
  border-bottom: 1px solid var(--color-border);
  font-family: var(--font-default);
  background-color: #0000;
  background-image: none;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 0 100%, 100% 100%;
  background-attachment: scroll;
  background-origin: padding-box;
  background-clip: border-box;
  border-radius: 0;
  outline: none;
  width: 100%;
  max-height: 200px;
  padding: 4px 0 8px;
  font-size: 1.25rem;
  line-height: 1.4;
  transition: background ease-out;
  background-image: linear-gradient(0deg, var(--color-success) 1px, #00968800 0), linear-gradient(0deg, #e8ecec 1px, transparent 0) !important;
}

.Lkja6W_textArea:focus {
  background-size: 100% 100%, 100% 100%;
  transition-duration: .3s;
}

.Lkja6W_textArea::placeholder {
  color: var(--color-text-gray);
}

.CygDsq_container {
  flex: 1;
  max-width: 450px;
}

.CygDsq_description {
  text-align: center;
  font-size: 1rem;
  line-height: 1.5;
}

:root {
  --color-background: #f8f9fa;
  --color-brand: #ff7b00;
  --color-default: #1b1d1e;
  --color-white: #fff;
  --color-border: #ebeeee;
  --color-success: #a4e750;
  --color-text-secondary: #989fa3;
  --color-text-gray: #bbb;
  --color-error: #ff5353;
  --color-primary: #458fe1;
  --color-primary-hover: #3772b4;
  --color-primary-active: #30649e;
  --loader-src: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGRpc3BsYXk9ImJsb2NrIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCIgdmlld0JveD0iMCAwIDEwMCAxMDAiPgogIDxkZWZzLz4KICA8Y2lyY2xlIGN4PSIzMCIgY3k9IjUwIiByPSIyMCIgZmlsbD0iI0ZGN0IwMCI+CiAgICA8YW5pbWF0ZSBhdHRyaWJ1dGVOYW1lPSJjeCIgYmVnaW49Ii0wLjVzIiBkdXI9IjFzIiBrZXlUaW1lcz0iMDswLjU7MSIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiIHZhbHVlcz0iMzA7NzA7MzAiLz4KICA8L2NpcmNsZT4KICA8Y2lyY2xlIGN4PSI3MCIgY3k9IjUwIiByPSIyMCIgZmlsbD0iIzQ1OEZFMSI+CiAgICA8YW5pbWF0ZSBhdHRyaWJ1dGVOYW1lPSJjeCIgYmVnaW49IjBzIiBkdXI9IjFzIiBrZXlUaW1lcz0iMDswLjU7MSIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiIHZhbHVlcz0iMzA7NzA7MzAiLz4KICA8L2NpcmNsZT4KICA8Y2lyY2xlIGN4PSIzMCIgY3k9IjUwIiByPSIyMCIgZmlsbD0iI0ZGN0IwMCI+CiAgICA8YW5pbWF0ZSBhdHRyaWJ1dGVOYW1lPSJjeCIgYmVnaW49Ii0wLjVzIiBkdXI9IjFzIiBrZXlUaW1lcz0iMDswLjU7MSIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiIHZhbHVlcz0iMzA7NzA7MzAiLz4KICAgIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9ImZpbGwtb3BhY2l0eSIgY2FsY01vZGU9ImRpc2NyZXRlIiBkdXI9IjFzIiBrZXlUaW1lcz0iMDswLjQ5OTswLjU7MSIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiIHZhbHVlcz0iMDswOzE7MSIvPgogIDwvY2lyY2xlPgo8L3N2Zz4K");
  --loader-width: 40px;
  --loader-height: var(--loader-width);
  --font-default: "NunitoSans", sans-serif;
}

@font-face {
  font-weight: normal;
  font-family: NunitoSans;
  font-style: normal;
  src: url("NunitoSans-Regular.e6a960a7.ttf");
  font-display: swap;
}

@font-face {
  font-weight: 500;
  font-family: NunitoSans;
  font-style: normal;
  src: url("NunitoSans-Regular.e6a960a7.ttf");
  font-display: swap;
}

@font-face {
  font-weight: 600;
  font-family: NunitoSans;
  font-style: normal;
  src: url("NunitoSans-SemiBold.2086c535.ttf");
  font-display: swap;
}

@font-face {
  font-weight: 700;
  font-family: NunitoSans;
  font-style: normal;
  src: url("NunitoSans-Bold.17d23c5b.ttf");
  font-display: swap;
}

@font-face {
  font-weight: 900;
  font-family: NunitoSans;
  font-style: normal;
  src: url("NunitoSans-Black.a0a87356.ttf");
  font-display: swap;
}

*, :before, :after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  font-family: var(--font-default);
  background-color: var(--color-background);
  height: 100%;
  margin: 0;
}
/*# sourceMappingURL=index.81defc58.css.map */
